import './list.scss';
import dataApi from '@/api/manage/rebate.js';
import dataMemberApi from '@/api/manage/member.js';
import dataProductApi from '@/api/manage/product.js';
import dataRelateRuleApi from '@/api/manage/rebateRule.js';

const dataType = [
  {
    label: '交易奖金',
    value: '1',
    disabled: false
  },
  {
    label: '团队奖金',
    value: '2',
    disabled: false
  }
];
export default {
  name: 'rebate-list',
  components: {},
  data () {
    return {
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      tableData: [],
      tbleOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        ...this.$store.getters.tableConfig,
        addBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        column: [
          {
            label: '产品',
            prop: 'product'
          },
          {
            label: '类型',
            row: true,
            type: 'radio',
            value: 0,
            dicData: [
              {
                label: '交易佣金',
                value: 1
              },
              {
                label: '团队佣金',
                value: 2
              }
            ],
            prop: 'type'
          },
          {
            label: '受益人',
            prop: 'member'
          },
          {
            label: '佣金规则',
            prop: 'rebate_rule'
          },
          {
            label: '贷款人',
            prop: 'guest'
          },
          {
            label: '公司名称',
            prop: 'company'
          },
          {
            label: '金额(元)',
            prop: 'amount'
          },
          {
            type: 'radio',
            label: '状态',
            value: 0,
            disabled: true,
            dicData: [
              {
                label: '已作废',
                value: 0
              },
              {
                label: '待发放',
                value: 1
              },
              {
                label: '发放中',
                value: 2
              },
              {
                label: '已发放',
                value: 3
              }
            ],
            span: 8,
            display: true,
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          }
        ]
      },
      searchOption: {
        column: [
          {
            label: '受益人',
            prop: 'member_id',
            type: 'table',
            span: 8,
            dialogWidth: '60%',
            dataType: 'number',
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '会员名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'nickname',
                  align: 'left'
                }
              ]
            },
            formatter: (row) => {
              if (!row.nickname) return '';
              return `${row.nickname}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                const postData = {
                  id: value
                };
                dataMemberApi.getOne.r(postData).then(res => {
                  callback({
                    ...res.data
                  });
                });
                return;
              }
              console.log(page, value, data);
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataMemberApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            props: {
              label: 'nickname',
              value: 'id'
            }
          },
          {
            label: '贷款产品',
            prop: 'product_id',
            type: 'table',
            span: 8,
            dialogWidth: '60%',
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'title',
                  align: 'left'
                }
              ]
            },
            formatter: (row) => {
              if (!row.title) return '';
              return `${row.title}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                const postData = {
                  id: value
                };
                dataProductApi.getOne.r(postData).then(res => {
                  callback({
                    ...res.data
                  });
                });
                return;
              }
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataProductApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            props: {
              label: 'title',
              value: 'id'
            }
          },
          {
            label: '佣金规则',
            prop: 'rebate_rule_id',
            type: 'table',
            span: 8,
            dialogWidth: '60%',
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'title',
                  align: 'left'
                }
              ]
            },
            formatter: (row) => {
              if (!row.title) return '';
              return `${row.title}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                const postData = {
                  id: value
                };
                dataRelateRuleApi.getOne.r(postData).then(res => {
                  callback({
                    ...res.data
                  });
                });
                return;
              }
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataRelateRuleApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            props: {
              label: 'title',
              value: 'id'
            }
          },
          {
            type: 'select',
            label: '类型',
            value: '',
            dicData: dataType,
            span: 8,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'type'
          },
          {
            type: 'select',
            label: '状态',
            value: '',
            dicData: [
              {
                label: '提交成功',
                value: 1
              },
              {
                label: '确认成功',
                value: 2
              },
              {
                label: '返佣成功',
                value: 3
              },
              {
                label: '已完成',
                value: 4
              },
              {
                label: '已作废',
                value: 0
              }
            ],
            span: 8,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'status'
          },
          {
            type: 'daterange',
            label: '日期范围',
            span: 8,
            display: true,
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            prop: 'datetime'
          }
        ],
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '搜索',
        emptyBtn: true,
        emptyText: '清空',
        menuPosition: 'center',
        tabs: false
      },
      searchData: {}
    };
  },
  computed: {},
  mounted () {
  },
  filters: {},
  methods: {
    // 分页
    onLoad (page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange (params, done) {
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.starttime = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.endtime = datetime[1];
      }
      delete params.datetime;
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset () {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    searchExcel () {
      const postData = {
        ...this.searchData
      };
      dataApi.exportFile.r(postData).then(res => {
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: false
        }).then(() => {
          window.location.href = res.data.file;
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消下载'
          });
        });
      });
    },
    rowView (row) {
      dataApi.getOne.r({ id: row.id }).then(res => {
        this.formDa = res.data;
      });
    },
    // 获取
    getList () {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then(res => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
