<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <div class="search">
      <avue-form ref="searchForm" v-model="searchData" :option="searchOption" @submit="searchChange" @reset-change="searchReset">
        <template slot="menuForm">
          <el-button icon="el-icon-download" @click="searchExcel()">导出</el-button>
        </template>
      </avue-form>
    </div>
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @refresh-change="getList"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
      <!-- <template slot-scope="{type,size,row}" slot="menu">
        <el-button icon="el-icon-view" :size="size" :type="type" @click.stop="rowView(row)">查看</el-button>
      </template> -->
    </avue-crud>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
